import request from '@/utils/request';
// import store from '../store'

// 获取个人信息
export function getAccInfo(params) {
  return request({
    url: `/icbc/auth/user`,
    method: 'get',
    params
  });
}
// 解绑验证码
export function getCode(data) {
  return request({
    url: `/code/verify`,
    method: 'post',
    data
  });
}
// 发送验证码
export function getVerifyCode(data) {
  return request({
    url: `/verify-code`,
    method: 'post',
    data
  });
}

// 重新绑定手机号
export function getRebindPhone(data) {
  return request({
    url: `/icbc/rebind`,
    method: 'post',
    data
  });
}

// 设置消费密码接口
export function getPassword(data) {
  return request({
    url: `/consumption/password`,
    method: 'post',
    data
  });
}

// import axios from 'axios'
// 部门主管获取人脸审批列表
// api/icbc/face/manager?current=1&size=10&manager=33479&status=0
export function getManagerFaceCheckList(params) {
  // 局部使用
  // const service = axios.create({
  //   baseURL: '', // 请求本地json文件，那么baseURL取空字符串，域名就会是项目域名
  //   timeout: 30000
  // })
  // return service.get('/json/person.json').then(res => {
  //   return res
  // })
  return request({
    url: `/icbc/face/manager`,
    method: 'get',
    params
  });
}

// 部门主管审批
export function getApproval(data) {
  // const service = axios.create({
  //   baseURL: '', // 请求本地json文件，那么baseURL取空字符串，域名就会是项目域名
  //   timeout: 30000
  // })
  // return service.get('/json/person.json').then(res => {
  //   console.log('部门主管已审批', res)
  //   return res
  // })
  return request({
    url: `/icbc/face/approval`,
    method: 'post',
    data
  });
}

// 用户提交人脸审核列表
export function getUserFaceCheckList(params) {
  return request({
    url: `/icbc/face/self`,
    method: 'get',
    params
  });
}

// 一键解除接口
export function getUnbound(params) {
  return request({
    url: `/icbc/unbind?custId=${params.id}`,
    method: 'post'
  });
}
